import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "radio-button",
      "style": {
        "position": "relative"
      }
    }}>{`Radio Button`}</h1>
    <p>{`Used when a list of two or more options are mutually exclusive, meaning the user must select only one option.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`The options must be grouped together with a label.`}</li>
      <li parentName="ul">{`An option must be selected by default. `}<strong parentName="li">{`Except`}</strong>{` when a default option can cause harm to the user or implies a critical/important action of the system: in these cases, you can present the list without any default option and add an option to clear the selection.`}</li>
      <li parentName="ul">{`Order the list in a logical order, for example: most likely to be selected first/ least likely last; simplest option to the most complex, etc.`}</li>
      <li parentName="ul">{`For a list with more than 4 options consider using `}<a parentName="li" {...{
          "href": "/components/select"
        }}>{`Select`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "examples",
      "style": {
        "position": "relative"
      }
    }}>{`Examples`}</h2>
    <Demo src='pages/components/radio-button/RadioExample' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      